/*eslint-disable*/
import classnames from "classnames";
import AuthHeader from "components/Headers/AuthHeader.js";
import React, { useEffect, useState, useRef } from "react";
import { Controller, useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import Select2 from "react-select2-wrapper";
import "react-select2-wrapper/css/select2.css";
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Row,
} from "reactstrap";
import { signupUser } from "../../../store/slices/auth/authActions";
import { useTranslation } from "react-i18next";
import { getBusinessUnitNames } from "../../../store/slices/factoryMinibusinessUnit/factoryMinibusinessUnitActions";
import NotificationAlert from "react-notification-alert";
import ErrorMessage from "../../../components/ErrorMessage/ErrorMessage";
import { useAppDispatch } from "../../../store/store";
import { notify } from "../../../utils/helpers";

const Register = () => {
  const notificationAlertRef = useRef(null);
  const appDispatch = useAppDispatch();
  const { t, i18n } = useTranslation();
  const [focusedName, setfocusedName] = useState(false);
  const [focusedEmail, setfocusedEmail] = useState(false);
  const [focusedPassword, setfocusedPassword] = useState(false);
  const history = useHistory();
  const businessUnitNames =
    useSelector((state) => state.factoryBusinessUnit.businessUnitNames) || [];
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    defaultValues: {
      username: "",
      email: "",
      userCode: "",
      password: "",
      mobile: "",
      defaultLang: "en",
    },
  });
  useEffect(() => {
    appDispatch(getBusinessUnitNames());
  }, []);
  const username = register("username", {
    required: "Please enter your Username",
    minLength: {
      value: 4,
      message: "Username must be at least 4 characters",
    },
  });
  const userCode = register("userCode", {
    minLength: {
      value: 4,
      message: "User Code must be at least 4 characters",
    },
  });
  const email = register("email", {
    required: "Please enter a valid Email",
  });
  const mobile = register("mobile", {
    required: "Please enter a valid Mobile",
    minLength: {
      value: 11,
      message: "Mobile must be at least 11 numbers",
    },
    pattern: {
      value: /^\d+$/,
      message: "Mobile must be numbers only",
    },
  });
  const factoryBusinessUnit = register("factoryBusinessUnit", {
    required: "Please enter a valid Location of idea",
  });
  const password = register("password", {
    minLength: {
      value: 8,
      message: "Password must be at least 8 characters",
    },
    maxLength: {
      value: 20,
      message: "Password must be at least 20 characters",
    },
    required: "Please enter a Password",
  });
  const onSubmit = async (data) => {
    try {
      data.email = data.email.toLowerCase();
      const res = await appDispatch(signupUser(data));
      if (res.error) {
        notify(
          "danger",
          `${res.payload.message}`,
          notificationAlertRef,
          "Something went wrong"
        );
      } else {
        notify(
          "success",
          "Registered Successfully",
          notificationAlertRef,
          "Success"
        );
        setTimeout(() => {
          history.push("/admin/dashboard");
          history.go(0);
        }, 1000);
      }
    } catch (e) {
      return e;
    }
  };
  return (
    <>
      <div className="rna-wrapper">
        <NotificationAlert ref={notificationAlertRef} />
      </div>
      <AuthHeader title={t("createAccount")} lead={t("kindlyLogin")} />
      <Container className="mt--8 pb-5">
        <Row className="justify-content-center">
          <Col lg="6" md="8">
            <Card className="bg-secondary border-0">
              <CardBody className="px-lg-5 py-lg-5">
                <div className="text-center text-muted mb-4">
                  <h2 className="text-muted">{t("signUp")}</h2>
                </div>
                <Form role="form" onSubmit={handleSubmit(onSubmit)}>
                  {/* Username */}
                  <FormGroup
                    className={classnames({
                      focused: focusedName,
                    })}
                  >
                    <InputGroup className="input-group-merge input-group-alternative mb-3">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="ni ni-single-02" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        placeholder={t("register.name")}
                        type="text"
                        name={username.name}
                        onChange={username.onChange}
                        onFocus={() => setfocusedName(true)}
                        onBlur={username.onBlur}
                        innerRef={username.ref}
                      />
                    </InputGroup>
                    <ErrorMessage errors={errors} property="username" />
                  </FormGroup>
                  <FormGroup
                    className={classnames({
                      focused: focusedName,
                    })}
                  >
                    <InputGroup className="input-group-merge input-group-alternative mb-3">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="ni ni-single-02" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        placeholder={t("register.userCode")}
                        type="text"
                        name={userCode.name}
                        onChange={userCode.onChange}
                        onFocus={() => setfocusedName(true)}
                        onBlur={userCode.onBlur}
                        innerRef={userCode.ref}
                      />
                    </InputGroup>
                    <ErrorMessage errors={errors} property="userCode" />
                  </FormGroup>
                  {/* Mobile */}
                  <FormGroup
                    className={classnames({
                      focused: focusedName,
                    })}
                  >
                    <InputGroup className="input-group-merge input-group-alternative mb-3">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="ni ni-mobile-button" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        placeholder={t("register.mobileNo")}
                        type="tel"
                        name={mobile.name}
                        onChange={mobile.onChange}
                        onFocus={() => setfocusedName(true)}
                        onBlur={mobile.onBlur}
                        innerRef={mobile.ref}
                      />
                    </InputGroup>
                    <ErrorMessage errors={errors} property="mobile" />
                  </FormGroup>
                  {/* Email */}
                  <FormGroup
                    className={classnames({
                      focused: focusedEmail,
                    })}
                  >
                    <InputGroup className="input-group-merge input-group-alternative mb-3">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="ni ni-email-83" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        placeholder={t("register.email")}
                        type="email"
                        onFocus={() => setfocusedEmail(true)}
                        onBlur={email.onBlur}
                        onChange={email.onChange}
                        innerRef={email.ref}
                        name={email.name}
                      />
                    </InputGroup>
                    <ErrorMessage errors={errors} property="email" />
                  </FormGroup>
                  {/* Password */}
                  <FormGroup
                    className={classnames({
                      focused: focusedPassword,
                    })}
                  >
                    <InputGroup className="input-group-merge input-group-alternative">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="ni ni-lock-circle-open" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        placeholder={t("register.password")}
                        type="password"
                        onFocus={() => setfocusedPassword(true)}
                        onBlur={password.onBlur}
                        onChange={password.onChange}
                        innerRef={password.ref}
                        name={password.name}
                      />
                    </InputGroup>
                    <ErrorMessage errors={errors} property="password" />
                  </FormGroup>
                  {/* businessUnit  */}
                  <FormGroup
                    className={classnames({
                      focused: focusedPassword,
                    })}
                  >
                    <InputGroup className="input-group-merge input-group-alternative">
                      <Controller
                        name="factoryBusinessUnit"
                        control={control}
                        render={({ field }) => (
                          <Select2
                            {...field}
                            // name={factoryBusinessUnit.name}
                            // onChange={factoryBusinessUnit.onChange}
                            // onFocus={() => setfocusedName(true)}
                            // onBlur={factoryBusinessUnit.onBlur}
                            // innerRef={factoryBusinessUnit.ref}
                            options={{
                              placeholder: "Select your Location of idea",
                            }}
                            data-minimum-results-for-search="Infinity"
                            data={businessUnitNames?.map((el) => {
                              return {
                                id: el._id,
                                value: el._id,
                                text: el.name[i18n.language],
                              };
                            })}
                          />
                        )}
                      />
                    </InputGroup>
                    <ErrorMessage
                      errors={errors}
                      property="factoryBusinessUnit"
                    />
                  </FormGroup>
                  <div className="text-center">
                    <Button className="mt-4" color="info">
                      {t("createAccount")}
                    </Button>
                  </div>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};
export default Register;
