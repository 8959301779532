import { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation, useParams } from "react-router-dom";
import Select2 from "react-select2-wrapper";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Form,
  Input,
  Row,
} from "reactstrap";
import SimpleHeader from "../../../components/Headers/SimpleHeader";
import { UserTypeSelect } from "../../../shared/enums/userType";
import { getBusinessUnitNames } from "../../../store/slices/factoryMinibusinessUnit/factoryMinibusinessUnitActions";
import { editUser } from "../../../store/slices/users/userActions";

const EditUser = () => {
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm();
  const history = useHistory();
  const dispatch = useDispatch();
  const businessUnitsNames = useSelector((state) => state.factoryBusinessUnit.businessUnitNames);
  const location = useLocation();
  const { data } = location.state;
  const id = useParams().id;
  const username = register("username", {
    required: "Please enter your Username",
    minLength: {
      value: 4,
      message: "Username must be at least 4 characters",
    },
  });
  const userCode = register("userCode", {
    minLength: {
      value: 4,
      message: "User Code must be at least 4 characters",
    },
  });
  const email = register("email", { required: "This is required" });
  const mobile = register("mobile", {
    required: "Please enter a valid Mobile",
    minLength: {
      value: 11,
      message: "Mobile must be at least 11 numbers",
    },
    pattern: {
      value: /^\d+$/,
      message: "Mobile must be numbers only",
    },
  });
  const factoryBusinessUnit = register("factoryBusinessUnit", {
    required: "Please enter a valid Location of idea",
  });
  const submitEditForm = (data) => {
    const user = {
      ...data,
      _id: id,
    };
    dispatch(editUser(user));
    history.push("/admin/user");
    history.go(0);
  };
  useEffect(() => {
    dispatch(getBusinessUnitNames());
  }, []);
  return (
    <>
      <SimpleHeader name="Edit User" parentName="User management" />
      <Container className="mt--6" fluid>
        <Row>
          <div className="col">
            <div className="card-wrapper">
              <Card>
                <CardHeader>
                  <h3 className="mb-0">Edit</h3>
                </CardHeader>
                <CardBody>
                  <Form role="form" onSubmit={handleSubmit(submitEditForm)}>
                    <div className="form-row">
                      <Col className="mb-3" md="4">
                        <label
                          className="form-control-label"
                          htmlFor="validationCustom01"
                        >
                          Name
                        </label>
                        <Input
                          id="validationCustom01"
                          placeholder="Name..."
                          type="text"
                          name="username"
                          defaultValue={data?.username}
                          onChange={username.onChange}
                          onBlur={username.onBlur}
                          innerRef={username.ref}
                        />
                        <small className="text-danger">
                          {errors.username && errors.username.message}
                        </small>
                      </Col>
                      <Col className="mb-3" md="4">
                        <label
                          className="form-control-label"
                          htmlFor="validationCustom02"
                        >
                          Email
                        </label>
                        <Input
                          id="validationCustom02"
                          placeholder="Email..."
                          type="text"
                          name="email"
                          innerRef={email.ref}
                          onChange={email.onChange}
                          defaultValue={data?.email}
                        />
                        <small className="text-danger">
                          {errors.email && errors.email.message}
                        </small>
                      </Col>
                      <Col className="mb-3" md="4">
                        <Controller
                          rules={{ required: "This is required" }}
                          defaultValue={data?.userType}
                          name="userType"
                          control={control}
                          render={({ field }) => (
                            <>
                              <label
                                className="form-control-label"
                                htmlFor="validationCustom03"
                              >
                                User Type
                              </label>
                              <Select2
                                {...field}
                                options={{
                                  placeholder: "Choose",
                                }}
                                data-minimum-results-for-search="Infinity"
                                data={UserTypeSelect}
                              />
                            </>
                          )}
                        />
                        <small className="text-danger">
                          {errors.userType && errors.userType.message}
                        </small>
                      </Col>
                    </div>
                    <div className="form-row">
                      <Col className="mb-3" md="4">
                        <label
                          className="form-control-label"
                          htmlFor="validationCustom01"
                        >
                          Mobile
                        </label>
                        <Input
                          id="validationCustom01"
                          placeholder="Mobile..."
                          type="text"
                          name="mobile"
                          innerRef={mobile.ref}
                          onChange={mobile.onChange}
                          defaultValue={data?.mobile}
                        />
                        <small className="text-danger">
                          {errors.moblie && errors.moblie.message}
                        </small>
                      </Col>
                      <Col className="mb-3" md="4">
                        <label
                          className="form-control-label"
                          htmlFor="validationCustom01"
                        >
                          User ID
                        </label>
                        <Input
                          id="validationCustom01"
                          placeholder="User ID..."
                          type="text"
                          name="userCode"
                          defaultValue={data?.userCode}
                          onChange={userCode.onChange}
                          onBlur={userCode.onBlur}
                          innerRef={userCode.ref}
                        />
                        <small className="text-danger">
                          {errors.userCode && errors.userCode.message}
                        </small>
                      </Col>
                      <Col className="mb-3" md="4">
                        <Controller
                          rules={{ required: "This is required" }}
                          defaultValue={data?.factoryBusinessUnit?._id}
                          name="factoryBusinessUnit"
                          control={control}
                          render={({ field }) => (
                            <>
                              <label
                                className="form-control-label"
                                htmlFor="validationCustom03"
                              >
                                Factory
                              </label>
                              <Select2
                                {...field}
                                options={{
                                  placeholder: "Choose",
                                }}
                                data-minimum-results-for-search="Infinity"
                                data={businessUnitsNames?.map((el) => {
                                  return {
                                    id: el._id,
                                    value: el._id,
                                    text: el.name.en,
                                  };
                                })}
                              />
                            </>
                          )}
                        />
                        <small className="text-danger">
                          {errors.factoryBusinessUnit && errors.factoryBusinessUnit.message}
                        </small>
                      </Col>
                    </div>
                    <Button color="primary" type="submit">
                      Submit
                    </Button>
                  </Form>
                </CardBody>
              </Card>
            </div>
          </div>
        </Row>
      </Container>
    </>
  );
};
export default EditUser;
